<template>
    <div class="entrance">
        <div class="topbar-container">
            <Topbar>
                <template slot="title">
                    <div class="title-container">
                        <img class="mr10" src="@/assets/title.png" alt="">
                        <span>河北法院干部政治素质和业绩档案管理系统</span>
                    </div>
                </template>
                <template slot="right">
                    <div class="right">
                        <div class="settings iconfont icon-settings" @click="goSystem" v-if="hasSettings"></div>
                        <div class="user">
                            <img src="@/assets/user.png" alt="">
                            <el-dropdown>
                                <span style="cursor: pointer;">
                                    <span>{{userInfo.realName}}</span>
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="userInfoView">个人信息</el-dropdown-item>
                                    <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </template>
            </Topbar>
        </div>
        <div class="content">
            <div class="logo">
                <img src="@/assets/logo.png" alt="" />
            </div>
            <div class="title">
                河北法院干部政治素质和业绩档案管理系统
            </div>
            <div class="ku-container">
                <div class="ku-item" v-for="(item,index) in kuList" :key="index" :style="{'background-color': item.bgColor}" @click="toHome(item)">
                    <div class="img">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="text" :class="`text-${index+1}`">
                        {{item.text}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Topbar from '@/components/topbar';
import { traverse } from '@/utils/handle.js';

export default {
    name: 'entrance',
    components: {
        Topbar
    },
    data(){
        return {
            kuList: [
                {
                    img: require('@/assets/lib1.png'),
                    text: '中院班子基层院长政治素质和工作业绩档案',
                    bgColor: '#73A0FF',
                    addPath: '',
                },
                {
                    img: require('@/assets/lib2.png'),
                    text: '全省基层法院院长梯队库',
                    bgColor: '#5FCFC9',
                    addPath: '/dean',
                },
				{
					img: require('@/assets/lib3.png'),
                    text: '全省法院优秀年轻干部信息库',
					bgColor: '#58C7FF',
                    addPath: '/cadre',
                },
            ]
        }
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo;
        },
        platformCenter() {
            return this.$store.getters.configMap['platformCenter'];
        },
        permissions(){
            return this.$store.getters.permissions;
        },
        hasSettings(){      //  是否有进入系统设置的权限
            let flag = false;
            // console.log(this.$router.options.routes);
            traverse(this.$router.options.routes,route => {
                if(route.name === 'system'){       //  matched包含所有上级route
                    flag = this.permissions.find(permission => route.meta.url.includes(permission));
                    // console.log(flag);
                }
            })
            return flag;
        }
    },
    methods: {
        hasLayoutPermiss(addPath){
            const target = this.$router.options.routes.find(route => route.path === `/layout${addPath}`);
            let urlList = [];
            traverse(target.children,route => {
                const urls = route.meta && route.meta.url && route.meta.url.split(',') || [];
                urlList.push(...urls);
            })
            
            return this.permissions.find(permission => urlList.find(url => url === permission));
        },
        toHome(item) {
            if(this.hasLayoutPermiss(item.addPath)){
                this.$router.push(`/layout${item.addPath}`);
            }else{
                this.$message.warning('您没有该库的查看权限');
            }
            
        },
        userInfoView() {
            const token = this.$store.getters.token;
            window.location = `${this.platformCenter}/user?WHRP_TOKEN=${token}`;
        },
        async logout() {
            this.$store.dispatch('common/logout');
        },
        goSystem(){
            const routeUrl = this.$router.resolve({
                name: 'layoutSystem',
            })
            window.open(routeUrl.href,'_blank');
        }
    },
};
</script>

<style scoped lang="less">
.entrance{
    width: 100vw;
    height: 100vh;
    background-image: url('~@/assets/bgc.png');
    background-size: 100% 100%;
    box-sizing: border-box;
    .topbar-container{
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        /deep/ .topbar{
            background-color: transparent;
            box-shadow: none;
        }
        .title-container{
            font-size: 18px;
            color: #fff;
            display: flex;
            align-items: center;
        }
        .right{
            display: flex;
            .settings{
                display: flex;
                align-items: center;
                color: #fff;
                padding: 0 5px;
                cursor: pointer;
            }
            .user{
                margin-left: 24px;
                display: flex;
                align-items: center;
                color: #fff;
                /deep/ .el-dropdown {
                    color: #fff;
                }
                img{
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
    .content{
        .logo{
            text-align: center;
        }
        .title{
            margin-top: 20px;
            text-align: center;
            font-size: 42px;
            color: #fff;
        }
        .ku-container{
            margin-top: 80px;
            display: flex;

            justify-content: center;
            .ku-item{
                width: 320px;
                min-height: 380px;
                background-color: #73A0FF;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 8px;
                margin: 0 40px;
                cursor: pointer;
                .img{
                    margin: 36px 0 43px 0;
                    width: 170px;
                    height: 170px;
                    // background-color: #fff;
                    // border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
					font-size: 0;
                }
                .text{
                    // padding: 0 15px;
                    font-size: 28px;
                    text-align: center;
                    color: #fff;
                    line-height: 1.3;
                }
                .text-1{
                    padding: 0 15px;
                }
                .text-2{
                    padding: 0 56px;
                }
                .text-3{
                    padding: 0 55px;
                }
            }
        }
    }

}
</style>